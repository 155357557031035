@import "./variables";
.breadcrumbs{
	margin: 10px auto;
	text-align: left;
	text-transform: uppercase;
	font-size: 11px;
	@media only screen and (min-width: $desktop-start-point) {
	  width: 1312px;
	}
	@media only screen and (max-width: $mobile-break-point) {
	  width: 100%;
	  height: 100%;
	}

	a{
		text-decoration: none;
		&:link, &:visited, &:hover, &:active{
			color: $copec-dark-blue;
		}
	}

	.menu-path{
		color: $copec-dark-blue;
		cursor: default;
	}
}
