@import "./variables";

.nav-bar{
  text-align: left;
  height: 65px;
  padding-top: 10px;
  letter-spacing: 0.5px;
  .logo{
    float: left;
    display: inline;
    height: 28px;
    @media only screen and (min-width: $desktop-start-point) {
      padding-top: 14px;
    }
  }

  .search{
    float: left;
    margin-right: 0 !important;
    padding: 0;
  }

  .actions-icons-header{
    float: left !important;
    margin-top: 5px !important;
    margin-left: 32px;
    padding: 0;
    .user{
      margin: 0 !important;
    }
  }

  .copec-logo-header{
    margin-right: 30px;
  }
  .sections{
    margin-top: 12px;
    display: inline-block;

    a{
      text-decoration: none;
      color: $copec-grey;
      &:hover{
        color: $header-hover;
      }
    }

    .menu__item, .menu__item-admin{
      color: $header-grey;
      font-weight: bold;
      cursor: pointer;
      float: left;
      letter-spacing: normal;
      span{
        margin-right: 4px;
      }
      .first-level img{
        margin-left: 10px;
      }
      &.open{
        button.first-level{
          background: $menu-background;
          color: $copec-dark-blue;
        }
        .szh-menu__item--open{
          background: $menu-background;
          color: $copec-dark-blue;
        }
      }

      .szh-menu--dir-bottom{
        border-radius: 4px;
        box-shadow: 0 0 6px 1px #bcbcbc;
        margin-top: 15px;
        width: 320px;
      }

      .menu__item-second-level{
        margin-bottom: 8px;
        .szh-menu__item--submenu{
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .szh-menu--state-open{
          width: 224px;
        }
      }

      .szh-menu--dir-bottom::after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 5px;
        top: 1px;
        left: 17%;
        box-sizing: border-box;
        border: 6px solid black;
        border-color: white;        
        transform-origin: 0 0;
        transform: rotate(135deg);
        box-shadow: -2px 2px 2px rgba(188, 188, 188, 0.5);
      }

      .szh-menu__item{
        color: $copec-dark-blue;
        &:hover{
          background: white;
        }
        &::after{
          content: '';
        }
      }

      .szh-menu__item--open{
        background: white;
      }

      .third-level-menu{
        font-size: 14px;
        color: $header-grey;
        padding-top: 11px;
        padding-bottom: 11px;
        &:hover{
          background: $menu-background;
          color: $copec-dark-blue;
        }

        .link-icon{
          height: 15px;
          margin-left: 3px;
          margin-top: 1px;
        }
      }
    }

    .menu__item, .menu__item-admin{
      .szh-menu__item:hover{
        background-color: $menu-background;
      }
    }
/* //Flechas submenu
    .menu__item-second-level{
      .szh-menu__item::after{
        content: '›';
        right: 1rem;
        margin-top: -3px;
      }
    }
*/
    .third-level-menu::after{
      content: '' !important;
    }
  

    .menu__item-admin{
      .szh-menu--state-open{
        left: -240px !important;
      }
      .szh-menu--dir-bottom::after{
        left: 270px;
      }

      .menu__item-second-level{
        .szh-menu--state-open{
          left: -224px !important;
        }
      }
/* //Flechas submenu
      .szh-menu__item{
        padding-left: 2rem;
      }

      .szh-menu__item::after{
        content: '‹';
        left: 1rem;
        margin-top: -3px;
      }
*/
      .third-level-menu{
        padding-left: 24px;
      }
    }

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      top: 112px;
      width: 1200px;
      left: 0;
      padding: 20px calc((100% - 1200px)/2) 40px;
      background-color: #ffffff;
      border-radius: 3px;
      color: $copec-grey !important;
      border-bottom: 1px solid #eeeeee;
      z-index: 110;

      .menu-detail{
        width: 100%;
        column-count: 4;
        padding-top: 7px;
      }

      .menu-section-title{
        color: $copec-dark-blue;
        font-size: 24px;
        font-weight: bold;
        margin-right: 30px;
      }

      .submenu{
        text-align: left;
        margin-bottom: 25px;
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
        .link-icon{
          height: 15px;
          margin-left: 3px;
          margin-top: 1px;
          position: absolute;
        }
      }
    }
     
    .dropdown-menu-item {
      display: flex;
      flex-direction: row;
      padding: 1px 0;
      font-size: 16px !important;

      &__title {
        color: $copec-dark-blue;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
        &:hover {
          cursor: default;
        }
      }

      &__leaf {
        font-size: 15px;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }
  .actions{
    float: right;
    button{
      background: none;
      border: none;
      margin-top: 5px;
      margin-right: 10px;
      vertical-align: top;
      img{
        width: 40px;
      }
    }

    .nav-bar-user{
      display: inline-block;
      position: relative;
    }

    .user{
      background: none;
      border: none;
      margin-top: 0;
      margin-right: 10px;
      vertical-align: top;
      img{
        width: 40px;
      }
    }

    .admin-item img{
      width: 40px;
    }

    .actions-border{
      background: #dee5ea;
      width: 1px;
      height: 40px;
      float: left;
      margin-top: 10px;
      margin-right: 31px;
    }
  }

  .navbar-sections-class{
    position: inherit;
    display: inline-block;
  }

  .navbar-icons{
    margin-top: -10px;
  }

  .header-menu{
    padding: 5px 10px;
    border-radius: 15px;
    background: white;
    border: none;
    color: $header-grey;
    font-size: 16px;
    font-weight: bold;
    &.header-menu-open{
      background: $sidebar-grey;
      color: $copec-dark-blue;
      margin-right: 10px;
    }
  }

  .szh-menu{
    z-index: 110;
  }
}

.users-menu{
  position: absolute;
  right: 0;
  padding: 10px 15px;
  box-shadow: 0 3px 5px -3px $copec-grey;
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  width: 260px;
  z-index: 150;
  .row {
    display: flex;
  }

  .column {
    flex: 50%;
    padding: 4px 0;
  }
}

.profile-link{
  color: $copec-dark-blue;
  text-decoration: none;
  margin-top: 5px;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
}

.close-session-button{
  background: $copec-dark-blue !important;
  color: white;
  border: none;
  padding: 7px 20px;
  border-radius: 18px;
  margin: 0 !important;
}

.ReactModal{
  &__Content{
    @media only screen and (min-width: $desktop-start-point) {
      right: auto !important;
    }
    @media only screen and (max-width: $mobile-break-point) {
      padding: 0;
    }
    top: 50% !important;
    left                  : 50% !important;
    bottom                : auto !important;
    margin-right           : -50% !important;
    transform             : translate(-50%, -50%) !important;
    background            : none !important;
    border                : none !important;
  }
  
  &__Overlay{
    background-color: rgba(0, 0, 0, 0.7) !important;
    color: white;
    z-index: 150;
  }
}

.sidebar-column {
  @media only screen and (min-width: $desktop-start-point) {
    width: 280px;
  }
  @media only screen and (max-width: $mobile-break-point) {
    width: 100%;
  }
}

.iframe-column {
  width: 100%;
}

.admin-item{
  img{
    width: 30px;
    margin-left: 6px;
  }
}

.dropdown-menu {
  &-enter {
    opacity: 0;
  }
  &-enter-done{
    position: absolute;
  }
 
  &-enter-active {
    opacity: 1;
    transition: 0.2s ease-in;
  }
 
  &-exit {
    opacity: 1;
  }
 
  &-exit-active {
    opacity: 0;
    transition: 0.2s ease-in;
  }
}