$copec-grey: #7b96ab;
$header-grey: #7c97ab;
$header-hover: #bdcbd5;
$copec-dark-blue: #003965;
$sidebar-grey: #f0f5f8;
$menu-background: #1080d526;
$copec-red: #ea212e;
$copec-dark-grey: #dde3e9;
$copec-light-blue: #0f68ac;
$copec-very-light-blue: #0090ff;
$copec-border: #809cb2;
$copec-green: #009b63;
$copec-footer: #9db1c0;
$copec-footer-blue: #1080d5;

/*
$mobile-break-point: 480px;
*/

$mobile-break-point: 1000px; /*Era 767*/
$desktop-start-point: 1001px; /*Era 768*/