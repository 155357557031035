@import "./variables";

.search-results{
	.back-button-mobile{
		top: 112px;
	}
	.hits-container{
		@media only screen and (max-width: $mobile-break-point) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
  @media only screen and (min-width: $desktop-start-point) {
		width: 1200px;
  }
  @media only screen and (max-width: $mobile-break-point) {
		width: 100%;
		margin-top: 106px;
  }
  min-height: 500px;
  margin: 40px auto;
  text-align: left;

  .no-results{
  	margin-top: 30px;
  }
  
  h1{
  	color: $copec-dark-blue;
  	font-weight: normal;
  	font-size: 24px;
  	margin-bottom: 30px;
  }

  h2{
		color: $copec-light-blue;
		font-size: 16px;
		margin-bottom: 25px;
  }

  hr{
  	border: none;
  	border-top: 2px solid $sidebar-grey;
  }

  .search-container{
		margin-top: 40px;
		margin-bottom: 40px;
		@media only screen and (min-width: $desktop-start-point) {
			display: table;
		}
		@media only screen and (max-width: $mobile-break-point) {
			position: relative;
			.lupa-mobile{
				width: 35px;
				height: 35px;
			}
		}
		margin: 0 auto;
  	font-size: 17px;
  	.search-bar{
			@media only screen and (min-width: $desktop-start-point) {
				display: table;
				width: 300px;
				margin-bottom: 20px;
				color: $copec-dark-blue;
				padding: 10px 30px;
				border-radius: 18px;
				border: 1px solid $copec-light-blue;
			}
			@media only screen and (max-width: $mobile-break-point) {
				background-image: none;
				width: calc(100% - 40px);
				padding: 0;
				margin-bottom: 10px;
				margin-left: 10px;
				height: 46px;
				border-radius: 23px;
				padding-left: 20px;
				border: 1px solid #809cb2;
				-webkit-appearance: none;				
			}
		}
		button{
			@media only screen and (min-width: $desktop-start-point) {
				padding: 10px 25px;
				color: white;
				margin-left: 15px;
				border: none;
				border-radius: 18px;
				background: $copec-light-blue;
			};
			@media only screen and (max-width: $mobile-break-point) {
				background-image: url('../assets/img/lupa_mobile.svg');
				background-size: 35px;
				border: none;
				background-color: transparent;
				height: 35px;
				width: 35px;
				position: absolute;
				top: 6px;
				right: 15px;
			};
		}
  }
  .hit{
  	text-decoration: none;
  	display: inline-block;
  	font-size: 15px;
		@media only screen and (min-width: $desktop-start-point) {
			width: 800px;
			.title{
	  	  color: $copec-dark-blue;
	  	}
    }
    @media only screen and (max-width: $mobile-break-point) {
  	  width: 100%;
  	  .title{
  	  	color: $copec-light-blue;
  	  }
    }
  	margin-bottom: 15px;
  	.title{
  	  font-weight: bold;
  	}
  	.description{
  	  color: #444444;
  	  line-height: 1.4;
  	  overflow: hidden;
  	  text-overflow: ellipsis;
  	  display: -webkit-box;
  	  -webkit-line-clamp: 2; /* number of lines to show */
  	  -webkit-box-orient: vertical;
  	}
  	&:hover{
  	  .title{
  	  	text-decoration: underline;
  	  }
  	}
  }
}
