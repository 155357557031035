@import "./variables";
.search-modal{
  text-align: center;
  input{
    @media only screen and (min-width: $desktop-start-point) {
      width: 350px;
      border: 1px solid white;
      border-radius: 20px;
      padding: 12px 30px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      width: 100%;
      height: 65px;
      border-radius: 40px;
      border: none;
      font-size: 16px;
      padding: 12px 20px;
    }
  }
}

input{
  &:invalid { 
    background-image: url(../assets/img/magnifier_blue_icon.svg);
    background-repeat: no-repeat;
    @media only screen and (min-width: $desktop-start-point) {
      background-size: 30px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      background-size: 48px;
    }
    background-position: calc(100% - 8px);
  }

  @media only screen and (min-width: $desktop-start-point) {
    &::-webkit-search-cancel-button {
      background-image: url(../assets/img/CERRARBUSCADOR.svg);
      background-position: calc(100% - 8px);
      background-size: 20px;
    }

    &:valid {
      background-image: url(../assets/img/magnifier_grey_icon.png);
      background-repeat: no-repeat;
      background-position: 8px;
    }
  }

  @media only screen and (max-width: $mobile-break-point) {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background-image: url(../assets/img/cross-with-background.svg);
      position: absolute;
      right: 25px;
      height: 42px;
      width: 42px;
    }

    &:valid{
      padding-right: 130px;
    }
  }
}
.react-autosuggest__suggestions-list{
  background: white;
  color: black;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: left;
  list-style-type: none;
  padding: 15px 0;
  li{
    background-image: url(../assets/img/magnifier_grey_icon.png);
    background-repeat: no-repeat;
    background-position: 8px;
    padding-right: 10px;

    padding: 5px 4px;
    padding-left: 50px;
    &:hover{
      background-color: #eeeeee;
      cursor: pointer;
    }
  }
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mobile-search-button{
  background-image: url(../assets/img/magnifier-blue.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 12px;
  right: 85px;
  width: 38px;
  height: 42px;
  border-right: 2px solid #dee4ea;
}

@media only screen and (max-width: $mobile-break-point) {
  .ReactModal__Content{
    padding: 0 !important;
    width: calc(100% - 28px);
  }
}