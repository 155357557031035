@import "./variables";

.floating-side-bar-trigger {
  position: fixed;
  top: 340px;
  left: 0;
  padding: 8px 16px;
  background: $copec-dark-blue;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  transform: rotateZ(-90deg);
  transform-origin: top left;
  font-weight: bold;
  font-size: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: -2px 2px 4px 0 rgba(128, 156, 178, 0.5);
  z-index: 101;
  &__icon {
    fill: white;
    margin-left: 25px;
  }
  @media screen and (max-width: 1399px) {
    display: none;
  }
}
.floating-side-bar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#1e1e1e, 0.78);
  z-index: -1;
  opacity: 0;
  &.close {
    animation: fadeOut 0.3s ease-in-out forwards;
  }

  &.open {
    animation: fadeIn 0.3s ease-in-out forwards;
  }
}

.floating-side-bar-menu {
  width: 320px;
  max-width: 100vw;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 152;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  &.open {
    transform: translateX(0);
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
  }
  &__logo-section {
    padding: 32px;
    text-align: left;
    border-bottom: 8px solid $sidebar-grey;
    img {
      height: 32px;
    }
  }
  &__links-section {
    padding: 32px 0 32px 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 18px;
      font-weight: bold;
      color: $copec-dark-blue;
      padding-bottom: 32px;
      margin-left: 32px;
      margin-right: 64px;
      border-bottom: 1px solid $copec-border;
      margin-bottom: 16px;
      margin-top: 0;
    }
    &__content {
      flex-grow: 1;
      overflow: auto;
      min-height: 0;
      padding-left: 32px;
      padding-right: 64px;
      max-height: calc(100vh - 230px);
    }
  }
}

.expanding-section {
  margin-bottom: 16px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    border: none;
    background: none;
    width: 100%;
    padding: 0;
    color: $copec-dark-blue;
    img {
      transform: rotateZ(0);
      transition: transform 0.3s ease-in-out;
    }
    &.open {
      img {
        transform: rotateZ(180deg);
      }
    }
  }
  &__content {
    transition: max-height 0.3s ease-in-out;
    padding: 0.1px 0;
    max-height: 0;
    height: auto;
    overflow: hidden;
    &__link {
      font-size: 14px;
      display: block;
      margin: 8px 0;
      color: $header-grey;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;
      &.active {
        color: $copec-dark-blue;
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    z-index: -1;
    opacity: 0;
  }
  1% {
    z-index: 151;
    opacity: 0;
  }
  100% {
    z-index: 151;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    z-index: 151;
    opacity: 1;
  }
  99% {
    z-index: 151;
    opacity: 0;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}
