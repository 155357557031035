@import "./variables";

button, select, a, body, input, .landscape-message {
  font-family: Quicksand, serif;
}

.App {
  text-align: center;
  font-family: Quicksand, serif;
}

.landscape-message { 
  display: none;
}

@media screen and (max-device-width: $mobile-break-point) and (orientation: landscape) { 
  .App { 
    display: none;
  }
  .landscape-message { 
    display: inline-block;
    color: $copec-dark-blue;
    font-size: 35px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    margin-top: 180px;
    text-align: center;
    font-family: Quicksand;
    .subtitle{
      font-size: 20px;
    }
  }
}

@media only screen and (max-device-width: $mobile-break-point) {
  body {
    overflow-x: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (min-width: $desktop-start-point) {
  .full-width {
    //margin-left: 50px;
    //width: calc(100% - 50px);
    width: 100%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

header {
  box-shadow: 0 2px 2px #eeeeee;
  margin-bottom: 3px;
  .header-content {
    @media only screen and (min-device-width: $desktop-start-point) {
      width: 1312px;
      margin: 0 auto;
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      display: none;
    }
  }

  .header-content-mobile {
    @media only screen and (min-device-width: $desktop-start-point) {
      display: inline;
    }

    @media only screen and (max-device-width: $mobile-break-point) {
      padding: 0 10px;
    }
  }
  @media only screen and (max-device-width: $mobile-break-point) {
    //position: sticky;
    //top: 0;
    z-index: 101;
    background: white;
    .mobile-menu,
    .mobile-sub-menu {
      position: fixed;
    }
  }
}

.App-link {
  color: #61dafb;
}

button:focus,
input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

#hidden-button-for-closing-menu {
  display: none;
}

.grey-background {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
}

.grey-background-visible{
  .floating-side-bar-trigger{
    z-index: 10;
  }
}

.grey-background-header-mobile{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 69px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 110;
}

.impersonated-user-band,
.impersonated-user-band-fixed {
  width: 100%;

  background-color: $copec-red;
  .band-message {
    background-color: white;
    color: $copec-dark-blue;
    display: inline-block;
    margin: 6px;
    padding: 3px 30px;
    border-radius: 10px;
    font-size: 12px;
    .band-message-exit {
      color: $copec-red;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &.impersonated-user-band-ro, &.impersonated-user-band-fixed-ro{
    background-color: $copec-green;
  }
}

.impersonated-user-band-fixed {
  position: fixed;
  z-index: 1000;
  top: 0;
}

.impersonate-modal-content {
  width: 250px;
  background: white !important;
  padding: 20px;
  border-radius: 8px;
  .close-modal {
    float: right;
    cursor: pointer;
  }

  .modal-info {
    display: inline-block;
    width: 100%;
    color: $copec-dark-blue;
    text-align: center;
  }

  .modal-central-icon {
    margin: 30px auto;
    display: block;
  }

  .title {
    color: $copec-dark-blue;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
  }

  hr {
    margin: 40px 0 25px;
    border: 1px solid #dddddd;
    border-bottom: none;
  }

  .modal-button {
    border: 1px solid $copec-dark-blue;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    font-size: 15px;
    font-weight: bold;
    border-radius: 20px;
    margin-bottom: 20px;
    &.accept {
      background: $copec-dark-blue;
      color: white;
    }
    &.cancel {
      color: $copec-dark-blue;
      background: white;
    }
  }
}

.content-container{
  min-height: 600px !important;
}

.no-scroll{
  overflow: hidden !important;
}

html{
  scroll-behavior: smooth;
  .cx-widget{
    z-index: 101 !important;
  }
}