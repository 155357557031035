@import "./variables";
.header-content-mobile {
  height: 66px;
  .nav-bar {
    height: fit-content;
    padding-top: 18px;
    .link-logo {
      height: 27px;
      left: 0;
      top: 50%;
    }
    .logo {
      padding-left: 4px;
      height: 26px;
    }
    .actions {
      right: 0;
      top: 50%;
      .menu__item,
      .admin-item {
        margin-right: 5px;
      }
      .user,
      .search,
      .admin-item img {
        margin-right: 0;
        margin-left: 0;
      }
      img {
        height: 27px !important;
        width: 27px;
        margin-right: 10px;
      }
    }
  }
  .users-menu {
    top: 70px;
    width: 270px;
    padding: 10px 25px;
  }
}
.mobile-menu,
.mobile-sub-menu {
  width: 100%;
  background-color: white;
  z-index: 500;
  position: absolute;
  top: 67px;
  right: 0;
  bottom: 0;
  left: 0;
  list-style-type: none;
  overflow: auto;

  ul {
    text-align: left;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid $sidebar-grey;
    color: $copec-dark-blue;
    font-variation-settings: "wght" 800;
    .menu-carret {
      text-align: right;
      padding-right: 27px;
      display: inline;
      float: right;
    }
    .caret-icon {
      transform: rotate(90deg);
    }
  }
}

.mobile-sub-menu {
  text-align: left;
  font-weight: bold;
  font-family: "Quicksand";
  letter-spacing: 0.35pt;

  .title {
    color: $copec-dark-blue;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;
    padding: 15px 15px;
    vertical-align: middle;
    line-height: 25px;
    font-variation-settings: "wght" 500;
    span {
      display: inline;
      padding-left: 7px;
    }
  }
  .subtitle {
    padding: 15px 20px;
    color: $copec-dark-blue;
    font-size: 14px;
    font-variation-settings: "wght" 600;
  }

  .leaves {
    padding: 15px 20px;
    color: $header-grey;
    font-size: 14px;
    font-weight: 600 !important;

    a {
      text-decoration: none;
      color: $copec-grey;
    }

    .link-icon {
      height: 15px;
      margin-left: 3px;
      margin-top: 1px;
      position: absolute;
    }
  }
}
.leaves-wrapper {
  padding-left: 15px;
}

.close-mobile-menu-button {
  background: none;
  border: none;
  float: right;
  padding-right: 15px;
  padding-top: 7px;
}

.close-mobile-submenu-button {
  background: none;
  border: none;
  float: left;
  padding: 0;
  margin-top: 2px;
}

.middle-wrapper {
  height: 40px;
  display: inline-block;
  width: 100%;
}
