@import "./variables";

.row {
  display: flex;
}

.column-left{
  @media only screen and (min-width: $desktop-start-point) {
    flex: 45%;
  }
  @media only screen and (max-width: $mobile-break-point) {
    padding-top: 115px;
    padding-left: 15px;
    padding-bottom: 55px;
  }
  background-image: url(../assets/img/account_image.jpg);
  background-size: cover;
  color: white;
  .column-left-content{
    width: calc(100% - 50px);
    position: relative;
    @media only screen and (min-width: $desktop-start-point) {
      top: 150px;
      left: 50px;
    }
    @media only screen and (max-width: $mobile-break-point) {
    }
    .left img{
      width: 45px;
    }
    .right{
      padding-left: 20px;
      text-align: left;
      .title{
        font-weight: bold;
        font-size: 22px;
      }
      .subtitle{
        font-size: 12px;
        @media only screen and (max-width: $mobile-break-point) {
          font-weight: bold;
        }
      }
    }
  }
}
.column-right{
  @media only screen and (min-width: $desktop-start-point) {
    flex: 55%;
    padding: 10px 40px;
  }
  @media only screen and (max-width: $mobile-break-point) {
    padding: 50px 14px 10px;
  }
	color: $copec-dark-blue;
	text-align: left;

	.section-info{
    @media only screen and (min-width: $desktop-start-point){
      margin-top: 50px;
    }
    @media only screen and (max-width: $mobile-break-point){
      .row .right{
        .title{
          font-size: 24px;
          margin-bottom: 0;
        }
        .subtitle{
          font-size: 16px;
        }
      }
    }
		img{
			width: 30px;
			margin-right: 10px;
			margin-top: 5px;
		}
	}

	.title, .data-title{
		color: $copec-dark-blue;
		font-weight: bold;
		font-size: 12px;
		margin-bottom: 5px;
		&.section-title{
			font-size: 30px;
		}
	}

	.subtitle, .data-info{
		color: $header-grey;
		font-size: 16px;

		@media only screen and (max-width: $mobile-break-point) {
			margin-bottom: 20px;
			font-weight: bold;
			li span{
				margin-left: -10px;
			}
			.eds-list-data{
				margin-left: 15px;
				line-height: 25px;
			}
		}
	}

	hr{
		border-top: 1px dashed $copec-grey;
    @media only screen and (min-width: $desktop-start-point) {
      width: 650px;
      margin: 15px 0;
    }
    @media only screen and (max-width: $mobile-break-point) {
      width: 100%;
      margin: 20px 0;
    }
	}
}

ul{
	list-style-position: inside;
	padding: 0;
	margin: 0;
}

.data{
	.left{
		width: 300px;
		@media only screen and (max-width: $mobile-break-point) {
			width: 100%;
		}
	}
	.right{
		@media only screen and (max-width: $mobile-break-point) {
			width: 100%;
			display: inline-block;
		}
	}
}

.edit, .save{
	margin: 30px 0;
	background: $copec-red;
	color: white;
	padding: 8px 35px;
	border-radius: 20px;
	border: 1px solid $copec-red;
	font-weight: bold;

	@media only screen and (max-width: $mobile-break-point) {
		width: 100%;
		padding: 16px;
		border-radius: 25px;
		font-size: 14px;
	}
}

.save{
	@media only screen and (max-width: $mobile-break-point) {
		background-color: $copec-dark-blue;
		border: 1px solid $copec-dark-blue;
	}
}

.my-account-message{
	background: $sidebar-grey;
	font-size: 13px;
	border-radius: 5px;
  @media only screen and (min-width: $desktop-start-point) {
  	padding: 6px 10px;
    width: 550px;
  }
  @media only screen and (max-width: $mobile-break-point) {
  	padding: 11px 12px;
  }
	box-sizing: border-box;
	.asterisk{
		color: $copec-red;
		font-weight: bold;
	}
	.email{
		text-decoration: underline;
	}
}

.my-account{
	@media only screen and (max-width: $mobile-break-point) {
		position: relative;
	}
  .column-right .row.data {
    @media only screen and (max-width: $mobile-break-point) {
      display: block;
    }
  }

	input[type=text]{
		border: 1px solid #dddddd;
    background: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: $copec-grey;
    @media only screen and (max-width: $mobile-break-point) {
    	width: calc(100% - 28px);
    	padding: 0 10px;
    	margin: 13px 0 31px;
    	height: 48px;
    }
	}

	.radio-button{
		color: $copec-grey;
		span{
			margin-right: 15px;
		}
		@media only screen and (max-width: $mobile-break-point) {
			input{
				width: 30px;
			}
		}
	}

	.cancel{
		margin: 30px 30px 0 0;
		color: $copec-dark-blue;
		background: white;
		padding: 8px 35px;
		border-radius: 20px;
		border: 1px solid $copec-dark-blue;
		font-weight: bold;
		@media only screen and (max-width: $mobile-break-point) {
			width: 100%;
			padding: 16px;
			border-radius: 25px;
		}

	}

	.account-dates{
		input{
			float: left;
	    width: 15px;
	    margin-right: 10px;
	    &.year{
	    	width: 30px;
	    }

	    @media only screen and (max-width: $mobile-break-point) {
	    	width: calc(33% - 35px);
	    	&.year{
		    	width: calc(33% - 35px);
		    }
	    }

		}
	}

	.my-account-table{
		thead{
			color: $copec-dark-blue;
		    font-weight: bold;
		    font-size: 12px;
		}

		th, td{
			padding: 2px 30px 2px 0;
		}
	}
}

.back-button-mobile{
	@media only screen and (min-width: $desktop-start-point) {
		display: none;
	}
	width: 30px;
	height: 30px;
	position: absolute;
	left: 15px;
	top: 46px;
}

.action-message{
	text-align: center;
	line-height: 24px;
	img{
		margin: 28px auto 19px;
		display: block;
	}
	.title{
		font-size: 30px;
		margin: 0;
	}
	hr{
		margin: 28px 0 48px;
	}
	.save{
		margin: 0 28px 74px;
		height: 50px;
		font-size: 14px;
		width: calc(100% - 48px);
	}
}
