@import "./variables";
.footer{
  background: url('../assets/img/footer_background.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 50px;

  padding: 20px 0 30px 0;

  @media only screen and (max-width: $mobile-break-point) {
    padding-top: 32px;
  }

  .footer-content{
    margin: 0 auto;
    @media only screen and (min-width: $desktop-start-point) {
      width: 940px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      width: 100%;
      height: 100%;
    }
  }

  .left-side{
    @media only screen and (min-width: $desktop-start-point) {
      display: inline-block;
      float: left;
      margin: 44px 0 0 38px;
      text-align: left;
    }
    @media only screen and (max-width: $mobile-break-point) {
      display: block;
      text-align: left;
      margin-left: 32px;
    }
    .logo{
      height: 24px;
    }
    .rrss{
      margin-top: 24px;
      a{
        padding-top: 10px;
      }
      .rrss-whatsapp{
        margin-left: 24px;
      }
    }
    .info{
      color: white;
      margin-top: 24px;
      font-size: 10px;
      font-weight: bold;

      p{
        margin: 5px 0;
      }
    }
  }
  .right-side{
    @media only screen and (min-width: $desktop-start-point) {
      display: inline-block;
      float: left;
      margin: 44px 0 0 40px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      display: block;
      max-width: 500px;
      margin: auto;
      padding: 20px 20px 20px 32px;
    }
    text-align: left;
    a{
      text-decoration: none;
      color: $copec-footer;
      display: block;
      margin-top: 12px;
      font-size: 14px;
      @media only screen and (min-width: $desktop-start-point) {
        margin-top: 14px;
      }
      &:hover{
        color: white;
      }
    }
    .footer-info{
      display: block;
      margin-top: 12px;
      img{
        margin-right: 15px;
        float: left;
        margin-top: 2px;
        &.footer-mail{
          margin-top: 6px;
        }
      }
      a{
        color: $copec-footer-blue;
        display: inline;
      }
    }
    .footer-info-text{
      color: $copec-footer;
      margin-top: 12px;
      font-size: 14px;
      display: inline;
    }
    .section-title{
      color: white;
      font-weight: bold;
      @media only screen and (max-width: $mobile-break-point) {
        background-image: url('../assets/img/cross.svg');
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 15px;
        width: 100%;
        display: inline-block;
      }
    }
  }
  .bottom{
    color: $copec-grey;
    font-size: 12px;

    @media only screen and (min-width: $desktop-start-point) {
      display: inline-block;
      height: 20px;
      width: 940px;
      padding-top: 30px;
      margin-top: 20px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      display: block;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 40px;
      font-size: 11px;
      line-height: 18px;
    }

    .footer-icons {
      height: 40px;
      padding: 20px 0px;
      .img-left{
        width: 105px;
      }
    }

    .img-left {
      @media only screen and (max-width: $mobile-break-point) {
        float: left;
        padding-left: 10%;
      }
    }

    .img-right {
      @media only screen and (max-width: $mobile-break-point) {
        float: right;
        padding-right: 10%;
      }
    }

    img{
      @media only screen and (min-width: $desktop-start-point) {
        margin: 0 40px;
      }
    }

    .tyc{
      color: white;
      font-weight: bold;
      text-decoration: none;
    }
    hr{
      border: none;
      border-top: 1px solid $copec-grey;
      margin: 25px 0;
    }
    .copyright{
      @media only screen and (max-width: $mobile-break-point) {
        margin: 0 30px;
      }
    }
  }
}