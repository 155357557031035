@import "./variables";
.loader-container{
	width: 100vw;	
	background-color:rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	left: 0;
  bottom: 0;
  right: 0;
	height: 100vh;
  z-index: 50000;
  img{
    width: 200px;
  }
}

.loader {
  position: absolute;
  left: calc(50% - 75px);
  top: calc(50% - 75px);
  width: 150px;
  height: 150px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}