@import "./variables";
#micro-component-window {
  width: 100%;
  min-height: 100%;
  z-index: 100;
  background-color: white;
  position: relative;
}

.restricted-width {
  width: 1312px;
  margin: auto;
  position: relative;
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  .breadcrumbs {
    font-size: 10px;
  }
}

.full-width {
  #sidebar {
    @media only screen and (min-width: $desktop-start-point) {
      margin-left: 10px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      margin-left: 14px;
      margin-right: 14px;
      width: unset;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .breadcrumbs {
    @media only screen and (min-width: $desktop-start-point) {
      margin-left: 20px;
    }
    @media only screen and (max-width: $mobile-break-point) {
      width: calc(100% - 28px);
      height: 100%;
      font-size: 9px;
      padding: 0 14px;
    }
  }
}
