@import "./variables";
.cta {
  background: $copec-dark-blue;
  color: white;
  border: none;
  padding: 10px 40px;
  border-radius: 20px;
  font-size: 20px;
}
.home {
  padding: 20px 0 30px 0;
  min-height: 500px;
  background-image: url('../assets/img/fondo_home.jpg');
  background-color: white;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  &.logged-in {
    margin: 0 auto -50px;
  }

  .login-button {
    position: absolute;
    top: 250px;
    left: 200px;
    background: $copec-dark-blue;
    color: white;
    border: none;
    padding: 10px 40px;
    border-radius: 20px;
    font-size: 20px;
  }
  &-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 25px;
    &.in-carousel {
      width: 100%;
      margin: 0 auto 0;
    }
    &.reversed {
      flex-direction: row-reverse;
      .home-section__content {
        margin-left: 20px;
        margin-right: 0px;
      }
    }
    &.small {
      font-size: 20px;
    }
    &.red-cta {
      .cta {
        background: $copec-red;
      }
    }
    &__content {
      max-width: 40%;
      flex: 1;
      margin-right: 20px;
    }
    &__title {
      font-weight: bold;
      color: $copec-dark-blue;
      font-size: 1.3em;
      margin: 0 0 0.3em;
    }
    &__description {
      color: $copec-grey;
      font-size: 0.8em;
      margin: 0 0 0.5em;
      font-weight: bold;
    }
    &__link {
      font-size: 14px;
      font-weight: bold;
      margin-top: 20px;
      text-decoration: none;
      display: inline-block;
      padding: 10px 30px;
    }
    &__img {
      max-width: 50%;
      flex: 0;
      margin: 0;
      max-height: 300px;
    }
  }
}

.mobile {
  &-content {
    display: block;
  }
  &-title {
    font-weight: bold;
    color: $copec-dark-blue;
    font-size: 1.3em;
    margin: 20px;
    text-align: left;
  }
  &-description {
    color: $copec-grey;
    font-size: 0.8em;
    margin: 20px;
    font-weight: bold;
    text-align: left;
  }
  &-link {
    font-size: 14px;
    font-weight: bold;
    margin: 20px;
    text-decoration: none;
    display: block;
    width: 55%;
    padding: 10px 30px;
  }
  &-img {
    margin: 20px;
    width: calc(100% - 2*20px);
  }
  
  &.red-cta {
    .cta {
      background: $copec-red;
    }
  }
}

.news-wrapper {
  margin: 50px auto 50px;
  @media only screen and (min-width: $desktop-start-point) {
    width: 1200px;
  }
  @media only screen and (max-width: $mobile-break-point) {
    width: 100%;
  }
}

.home-carousel {
  @media only screen and (min-width: $desktop-start-point) {
    margin: 80px auto 0;
    max-width: 1200px;
  }
  @media only screen and (max-width: $mobile-break-point) {
    width: 100%;
    margin: 20px 0;
  }
}

// slider custom styles
.carousel {
  &__slide {
    list-style-type: none;
  }
  &__slider-tray {
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
    &-wrapper {
      overflow: hidden;
    }
  }
  &__dot {
    width: 10px;
    height: 10px;
    background: rgba($copec-dark-blue, 0.4);
    border: none;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    &--selected {
      background: $copec-dark-blue;
    }
    &-group {
      @media only screen and (min-width: $desktop-start-point) {
        margin-top: 100px;
      }
      @media only screen and (max-width: $mobile-break-point) {
        margin-top: 20px;
      }
    }
  }
}
